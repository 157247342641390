import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

/**
 * The shared module provides commonly used imports to save code
 *
 * @export
 */
@NgModule({
  imports: [CommonModule, ReactiveFormsModule, TranslateModule],
  exports: [CommonModule, ReactiveFormsModule, TranslateModule]
})
export class SharedModule { }
